<template>
  <div>
    <NavBar
      title="账户流水"
      left-arrow
      fixed
      @click-left="$router.replace({name: 'User'})"
      placeholder
    >
      <template #right>
        <DropdownMenu>
          <DropdownItem v-model="type" :options="dropOptions" />
        </DropdownMenu>
      </template>
    </NavBar>
    <PullRefresh v-model="refreshing" @refresh="onRefresh">
      <List
        ref="list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <!-- <ListTransition>
          <component
            :is="false ? 'ListWithdrawItem' : 'ListItem'"
            v-for="(v, k) in logs"
            :key="k"
            :value="v"
            :type="type"
            :data-index="k"
            :data-page-size="pageSize"
          ></component>
        </ListTransition> -->
        <div ref="container" v-for="(subList, pk) in allList" :key="pk">
          <Sticky offset-top="48px" :container="container[pk]">
            <transition name="title" appear>
              <div class="lcf-time-line">
                {{ subList.addtime }}
              </div>
            </transition>
          </Sticky>
          <ListTransition appear>
            <component
              :is="false ? 'ListWithdrawItem' : 'ListItem'"
              v-for="v in subList.list"
              :key="v.log_id"
              :value="v"
              :type="type"
              :data-index="v.$idx"
              :data-page-size="pageSize"
            ></component>
          </ListTransition>
        </div>
      </List>
    </PullRefresh>
    <div v-if="finished" class="lcf-common-bottom-tip">
      市场有风险，出借需谨慎
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  Notify,
  PullRefresh,
  List,
  DropdownMenu,
  DropdownItem,
  Sticky,
} from 'vant'
import { mapActions } from 'vuex'
import ListItem from './Item.vue'
import ListWithdrawItem from './WithdrawItem.vue'

export default {
  components: {
    NavBar,
    PullRefresh,
    List,
    DropdownMenu,
    DropdownItem,
    ListItem,
    ListWithdrawItem,
    Sticky,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      toPage: 1,
      pageSize: 15,
      totalPage: null,

      // 源数据
      logs: [],

      // 按时间分类处理后的数据
      allList: [],

      container: [],

      //(0-所有 1-充值 2-提现 3-出借 4-回款)
      type: 1, //0,
      dropOptions: [
        // { text: '全部', value: 0 },
        { text: '充值', value: 1 },
        { text: '提现', value: 2 },
        { text: '出借', value: 3 },
        { text: '回款', value: 4 },
      ],
    }
  },
  mounted() {
    let cb = function (val) {
      this.toPage = 1
      this.logs = []
      this.allList = []
      this.resetLoadingStatus()
      this.$nextTick(() => {
        this.$refs.list.check()
      })
    }
    let unwatch = this.$watch('type', cb)
    this.$once('hook:beforeDestroy', function () {
      unwatch()
      this.logs = []
      this.allList = []
    })
  },
  methods: {
    ...mapActions(['requestAccountLogs']),
    resetLoadingStatus() {
      this.loading = false
      this.finished = false
      this.refreshing = false
      this.error = false
    },
    async showLogs() {
      let logs = []
      let error, data
      ;[error, data] = await to(
        this.requestAccountLogs({
          page: this.toPage,
          pageSize: this.pageSize,
          tradeType: this.type,
        })
      )

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data && data.code === -1) {
        logs = data.data
        this.totalPage = data.total
      }
      return logs
    },
    async onLoad(reloadData) {
      if (this.toPage > this.totalPage && this.totalPage != null) {
        this.finished = true
        return
      }

      let error, logs
      ;[error, logs] = await to(this.showLogs(this.toPage))

      if (error) {
        this.refreshing = false
        this.loading = false
        this.error = true
        return
      }

      if (reloadData === true) {
        this.logs.length = 0
      }

      this.logs.push(...logs)
      this.toPage++
      this.loading = false
      this.refreshing = false

      this.gen(this.allList, logs)
    },
    onRefresh() {
      let reloadData = true

      this.toPage = 1
      this.logs.length = 0

      this.finished = false

      this.loading = true
      this.onLoad(reloadData)
    },
    gen(srcArr, dstArr) {
      let ret, item
      dstArr.forEach((v, idx) => {
        // $idx用于计算动画延迟

        // 已有列表最后一项key
        let preAddtime = srcArr[srcArr.length - 1]?.addtime
        if (this.equal(preAddtime, v.addtime)) {
          srcArr[srcArr.length - 1].list.push({ ...v, $idx: idx })
          return
        } else {
          item = {
            addtime: v.addtime.substr(0, 7),
            list: [{ ...v, $idx: idx }],
          }
          srcArr.push(item)
        }
      })

      this.$nextTick(() => {
        this.container = this.$refs.container
      })
    },
    equal(l, r) {
      if (l && r) {
        return l.substr(0, 7) === r.substr(0, 7)
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.lcf-time-line {
  font-size: 16px;
  padding: 0 24px;
  margin: 0 0 -8px;
  background: $lcf-color-bg-gray-light;
  line-height: 32px;
  color: $lcf-color-text-gray;
}
.title-enter,
.title-leave-to {
  opacity: 0;
  transform: translateX(50%);
}
.title-enter-to,
.title-leave {
  opacity: 1;
  transform: translateX(0%);
}
.title-enter-active,
.title-leave-active {
  transition: all 300ms;
}
</style>
