<template>
  <div class="lcf-logs-item">
    <div :class="`lcf-logs-${logType}`"></div>
    <div class="lcf-logs-infoarea">
      <div class="lcf-logs-content">
        <span>{{ logTypeCn }}</span>
        <span class="van-ellipsis">{{ value.subtitle }}</span>
        <span :class="`lcf-logs-${logType}-amount`">{{ value.income }}元</span>
      </div>
      <div class="lcf-logs-time">{{ value.addtime }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountLogsItem',
  props: ['value', 'type'],
  data() {
    return {
      //
    }
  },
  computed: {
    logType() {
      let maps = ['quanbu', 'chongzhi', 'tixian', 'chujie', 'huikuan']
      return maps[this.type]
    },
    logTypeCn() {
      let maps = ['全部', '充值', '提现', '出借', '回款']
      return maps[this.type]
    },
  },
}
</script>

<style lang="scss" scoped>
.lcf-logs-item {
  display: flex;
  flex-flow: row nowrap;
  & > div:first-child {
    flex: 1 0;
    padding-left: 24px;
  }
  & > div:last-child {
    flex: 4 1;
  }
  .lcf-logs-quanbu {
    background: content-box left / 24px auto no-repeat
      url('~@/assets/img/account_logs/ic_weiquanbu.png');
  }
  .lcf-logs-chongzhi {
    background: content-box left / 24px auto no-repeat
      url('~@/assets/img/account_logs/ic_weichongzhi.png');
  }
  .lcf-logs-tixian {
    background: content-box left / 24px auto no-repeat
      url('~@/assets/img/account_logs/ic_weitixian.png');
  }
  .lcf-logs-chujie {
    background: content-box left / 24px auto no-repeat
      url('~@/assets/img/account_logs/ic_weichujie.png');
  }
  .lcf-logs-huikuan {
    background: content-box left / 24px auto no-repeat
      url('~@/assets/img/account_logs/ic_weihuikuan.png');
  }
  .lcf-logs-infoarea {
    display: flex;
    flex-flow: column nowrap;
    .lcf-logs-content {
      flex: 1 1;
      line-height: 48px;
      font-size: 16px;
      display: flex;
      padding: 0 16px 0 0;
      flex-flow: row nowrap;
      span:nth-child(1) {
        flex: 1 0;
        text-align: left;
      }
      span:nth-child(2) {
        flex: 5 1;
      }
      span:nth-child(3) {
        flex: 2 1;
        white-space: nowrap;
        font-size: 12px;
        text-align: right;
      }
      .lcf-logs-chongzhi-amount,
      .lcf-logs-huikuan-amount {
        color: $lcf-color-text-red;
      }
      .lcf-logs-tixian-amount,
      .lcf-logs-chujie-amount {
        color: $lcf-color-text-green;
      }
    }
    .lcf-logs-time {
      flex: 1 1;
      line-height: 32px;
      font-size: 12px;
      color: $lcf-color-text-gray;
    }
  }
}
</style>
